<template>
  <div>
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10">Departamentos</h1>
        <hr class="">
      </v-col>
      <v-col cols="3" sm="3" md="3" class="mt-10  ">
        <v-text-field v-model="searchData" label="Buscar"></v-text-field>
      </v-col>
      <v-col cols="6" class="mt-10  ">
      </v-col>

      <v-col cols="3" class="mt-10 ">
        <v-btn color="primary" :to="{ name: 'DepartmentsCreate' }" style="width: 100%">
          Crear nuevo
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="departments"
            :items-per-page="5"
            class="elevation-2"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <div class="mr-2 card-icons-table">
                <router-link :to="{ name: 'DepartmentsEdit', params: { id: item.id } }">
                  <v-icon medium color="black">mdi-magnify</v-icon>
                </router-link>
              </div>
              <div class="card-icons-table " style="cursor:pointer" @click="deleteData(item.id)">
                <v-icon medium color="black">mdi-delete</v-icon>
              </div>
            </div>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>

</template>

<script>


import {mapActions} from "vuex";
import Overlay from "@/components/Overlay.vue";

export default {
  name: "Tenders",
  components: {Overlay},
  data() {
    return {
      headers: [
        {text: 'id', value: 'id'},
        {text: 'Nombre', value: 'nombre'},
        {text: 'Acciones', value: 'action', sortable: false},
      ],
      departments: [],
      searchData: null,
      overlay: false
    };

  },
  async created() {
    await this.findAll();

  },
  methods: {
    ...mapActions('users', ['findDepartments','deleteDepartments']),
    async deleteData(id){
      this.overlay = true;
      const response = await this.deleteDepartments(id)
      if (response.msg){
        this.sweetAlertSuccess('Se Borro correctamente')
       await this.findAll();
      }else{
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async findAll() {
      const response = await this.findDepartments();
      if (response.length > 0) {
        this.departments = response;
        return
      }
      this.departments = [];
    },

    download(item) {
    },
    search(item) {
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}
</style>
